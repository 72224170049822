import React from 'react';
import { Button } from "antd";
import "./home.css"
import Logo from "./Logo";
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

const slideDesc = {
  1: { title: "ReplyReach", desc: "Search & analyze social media posts with AI", img: "./replyreach.png", link: 'https://replyreach.quest' },
  2: { title: "Chat2Course", desc: "Intelligent course development assistant", img: "./chat2course.png", link: 'https://chat2course.com' },
  3: { title: "ExtractNinja", desc: "Bulk extract & analyze data from PDFs into Excel", img: "./extractninja.png", link: 'https://extractninja.org' },
}

const PreviousWorks = ({ isLandscape, tooWide }) => {
  const [currentSlide, setCurrentSlide] = React.useState(1)

  return (
    <div style={{ maxWidth: 900, margin: 'auto', marginTop: 40, marginBottom: 20 }}>
      <h2>
        Our previous works
      </h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <div style={{ display: 'flex', gap: 10, justifyContent: 'space-between', alignItems: 'center', flexDirection: window.innerWidth > 580 ? 'row' : 'column'  }}>
          <div style={{ display: 'flex', alignItems: 'baseline', gap: 15, alignSelf: 'start' }}>
            <div>
              <h3>{slideDesc[currentSlide].title}</h3>
              <p style={{ margin: 0 }}>{slideDesc[currentSlide].desc}</p>
            </div>
          </div>
          <div style={{ display: 'flex', gap: 5, alignSelf: 'end' }}>
            <Button icon={<LeftOutlined />} onClick={() => { currentSlide > 1 && setCurrentSlide(currentSlide - 1) }} />
            <Button icon={<RightOutlined />} onClick={() => { currentSlide < 3 && setCurrentSlide(currentSlide + 1) }} />
          </div>
        </div>
        <a target='__blank' href={slideDesc[currentSlide].link}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <img style={{ width: '100%', boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 6px 0px", borderRadius: 10 }} alt="" src={slideDesc[currentSlide].img} />
          </div>
        </a>
      </div>
    </div>
  )
}

const Separator = () => (
  <div style={{ width: '100%', height: 1, backgroundColor: 'lightgrey', margin: '40px 0' }} />
)

const DetailsList = ({ title, list }) => {
  return (
    <>
      <h2 style={{ marginTop: 40 }}>{title}</h2>
      <div>
        {
          list.map((item) => (
            <p>
              <b>{item.title} </b>
              {item.description}
            </p>
          ))
        }
      </div>
    </>
  )
}

const whatsIncluded = [
  {
    title: 'All-in-one Solution:',
    description: 'We develop a web application as your core product, a landing page to drive sales, and an SEO-optimized blog to attract organic traffic.'
  },
  {
    title: 'Seamless Integration:',
    description: 'Your product will be integrated with OpenAI, payment gateways, user authentication systems, and email marketing platforms.'
  },
  {
    title: 'Cutting-Edge Technology:',
    description: 'We use the latest & matured technologies to ensure your product is fast, stable, and scalable.'
  },
  {
    title: 'Simplicity is Key:',
    description: `We help you define your MVP's core features and keep it simple. Stop planning and start testing your idea with real users. `
  }
]

const ourProcess = [
  {
    title: 'Specifications:',
    description: 'We dive into your vision and create a comprehensive specification document that serves as our roadmap and agreement throughout the development journey.'
  },
  {
    title: 'Development:',
    description: 'Witness your MVP come to life in real-time via a dedicated Slack channel, with regular progress updates at each milestone.'
  },
  {
    title: 'Launch:',
    description: 'We deploy the product on your server, provide thorough onboarding, and equip you with the knowledge to take full control of your creation.'
  }
]

const Home = () => {
  const logoSize = 28;
  const pricingRef = React.useRef(null);

  const handleClickViewPricing = () => {
    pricingRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div style={{ display: 'flex', maxWidth: 650, padding: 20, margin: 'auto' }}>
      <div>
        <Logo width={logoSize} height={logoSize} />
        <Separator />
        <div>
          <h1>Unleash the power of AI for your next SaaS product. Fast, affordable, and stress-free development</h1>
          <p style={{ fontSize: 25, fontWeight: 300 }}>Transform your idea into an AI-powered, market-ready MVP in less than a month. With all you need to launch and run it.</p>
          <div style={{ display: 'flex', gap: 10 }}>
            <Button onClick={handleClickViewPricing} size='large'>See pricing</Button>
            <a href="mailto:resz.mzahid@gmail.com"><Button size='large'>Contact me</Button></a>
          </div>
        </div>
        <Separator />
        <PreviousWorks />
        <Separator />
        <DetailsList
          title="Get Your Idea Out of Your Head and Into the Market"
          list={whatsIncluded}
        />
        <Separator />
        <DetailsList
          title="From concept to reality. Our process"
          list={ourProcess}
        />
        <div ref={pricingRef} />
        <Separator />
        <div>
          <h2>Fixed price with no hidden costs</h2>
          <h3>$1,999/MVP</h3>
          <ul>
            <li>Web app & landing page</li>
            <li>Your mockup to code.</li>
            <li>We'll build the UI with a beautiful component library based on your mockup.</li>
            <li>Integrations: OpenAI, payments, API, databases, authentication & analytics</li>
            <li>Setup and deployment.</li>
            <li>Onboarding to teach you how to handle your product.</li>
          </ul>
          <h3>$2,999/MVP</h3>
          <ul>
            <li>Web app, landing page, blog & admin panel</li>
            <li>Your design to code.</li>
            <li>No design? We'll build the UI with a beautiful component library based on your mockup.</li>
            <li>Integrations: OpenAI, payments, API, databases, authentication, analytics & newsletter</li>
            <li>Setup and deployment.</li>
            <li>Onboarding to teach you how to handle your product.</li>
          </ul>
        </div>
        <Separator />
        <div>
          <h2>About the founder</h2>
          <p>
            Hi! I'm <b>Resz</b>, a former full-time developer who always aspired to create my own products.
            The release of ChatGPT made me realize the immense potential of AI in revolutionizing various industries.
            Inspired by this, I took a leap of faith and launched my own ventures.
            It was an incredible feeling when my first two projects succeeded, generating substantial revenue and leaving users satisfied.
          </p>
          <p>
            This journey, along with witnessing great ideas struggle to get off the ground,
            inspired me to establish <b>10BrandCompany</b>.
            Our mission is simple: we're here to help you bring your product to life.
            You come up with the idea, and we'll take care of the coding.
          </p>
          <p>
            The biggest mistakes in MVP development? Poor implementation or overengineering.
            Some products crumble under the weight of bugs and missing features,
            while others become bloated monsters, expensive to launch and maintain.
          </p>
          <p>
            We can save you from both. We build lean, AI-powered SaaS products with just the right features for
            smooth performance and user satisfaction.
            We ensures that your product is not only functional but also intelligent,
            adapting to user needs and preferences.
          </p>
        </div>
        <Separator />
        <div style={{ marginBottom: 20 }}>10BrandCompany. All rights reserved © 2024.</div>
      </div>
      <div>

      </div>
    </div>
  );
}

export default Home;
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from "react-router-dom";

import 'antd/dist/reset.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <ConfigProvider
        theme={{
          dark: true,
          token: {
              fontFamily: 'Outfit',
              colorPrimary: '#0046f5',
          },
        }}
      >
        <App />
      </ConfigProvider>
    </BrowserRouter>
);

reportWebVitals();
